<template>
  <div>
    <h2>Заявка отправлена</h2>
    <h3>В ближайшее время с вами свяжется специалист</h3>
  </div>
</template>

<script>
export default {
name: "CalcSuccessSend"
}
</script>

<style scoped>

</style>