<template>
  <div class="calc-content">
    <div class="calc-content__left" v-if="step !== 3">
      <CalcListParams v-if="step === 1"/>
      <CalcUserData v-if="step === 2"/>
    </div>
    <div class="calc-content__right" v-if="step !== 3">
      <CalcSelectedTarifs/>
    </div>
    <CalcSuccessSend v-if="step === 3"/>
  </div>
</template>

<script>
import CalcSelectedTarifs from './components/CalcSelectedTarifs'
import CalcListParams from './components/CalcListParams'
import CalcUserData from './components/CalcUserData'
import CalcSuccessSend from "./components/CalcSuccessSend";

export default {
  name: 'App',
  components: {
    CalcListParams,
    CalcSelectedTarifs,
    CalcUserData,
    CalcSuccessSend
  },
  mounted: function() {
    this.$store.dispatch('setParamsTarifs');
  }
}
</script>

<style lang="scss">

</style>
